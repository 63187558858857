import React, { useState, useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { Band, Order, UserTicket, UserProduct } from 'swingby-shared';
import { bandPrevPageState } from '../../atoms';
import Logo from '../../public/logo.svg';
import Setting from '../../public/setting.svg';
import { TextTitle } from '../Typography';
import { QUERY_BAND_INFO, QUERY_AVAILABLE_ORDERS } from '../../apollo/queries';
import { DocumentType, IResult } from '../../apollo/types';
import { bandVar, isLoggedInVar, userVar } from '../../apollo/cache';
import { Time } from './Back';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import InstallModal from '../Modal/InstallModal';
import { OrderStatus } from '../../constants';
import myticket from '../../public/myticket.svg';
import time from '../../public/icon-time-blue.svg';

interface Props {
  title?: string;
}

export const GNB = ({ title }: Props) => {
  const router = useRouter();
  const user = userVar();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const { isMobile } = useDeviceDetect();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showBadge, setShowBadge] = useState<boolean>(false);
  const [showInstallModal, setShowInstallModal] = useState<boolean>(false);
  const [showTimeLeft, setShowTimeLeft] = useState<boolean>(false);
  const updateScroll = () => setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  const setBandPrevPage = useSetRecoilState(bandPrevPageState);

  const { data: band } = useQuery<IResult<Band>>(QUERY_BAND_INFO, {
    skip: !isLoggedIn,
    fetchPolicy: 'network-only',
    onCompleted({ result }) {
      if (result) bandVar(result);
    },
  });

  const { data: orders } = useQuery<IResult<Order[]>>(QUERY_AVAILABLE_ORDERS, {
    skip: !(isLoggedIn && router.pathname === '/ticket'),
    fetchPolicy: 'cache-and-network',
    variables: { filter: { status: OrderStatus.Paid } },
  });

  const numOfUsableTicket = orders?.result
    .filter(
      (order) =>
        order.userTickets.length === 0 || new Date(order.userTickets[0].reservedAt!).getTime() > new Date().getTime(),
      // product의 경우 reservedAt 없음
    )
    .reduce((acc, order) => {
      const availableItems =
        order.userTickets?.filter((ut: UserTicket) => !ut?.metaData?.parentPackage && ut.status === 'paid').length +
        order.userProducts?.filter((up: DocumentType<UserProduct>) => {
          const isPackage = (item: any): item is UserProduct => Boolean(item?.price === 0);
          const allPackageItemsUsed =
            order.userProducts
              .filter((userProduct) => userProduct.metaData.parentPackage === up.id)
              .every((filteredUserProduct) => filteredUserProduct.status === 'used') &&
            order.userTickets
              .filter((userTicket) => userTicket.metaData.parentPackage === up.id)
              .every((filteredUserTicket) => filteredUserTicket.status === 'used');
          const paidProduct = !up?.metaData?.parentPackage && up.status === 'paid';
          return isPackage(up) ? !allPackageItemsUsed : paidProduct;
        }).length;
      return acc + availableItems;
    }, 0);

  const renderBandStatus = () => {
    if (band?.result)
      return (
        <SyncButton
          className="synced"
          onClick={() => {
            if (!showTimeLeft) {
              setShowTimeLeft(true);
              setTimeout(() => setShowTimeLeft(false), 1500);
            }
          }}
        >
          연동중
          <Image src={time} width={14} height={14} alt="time_icon" />
        </SyncButton>
      );
    else
      return (
        <SyncButton
          onClick={() => {
            setBandPrevPage(router.pathname);
            if (!user) return router.push('/login');
            if (isMobile) return router.push('/band');
            else setShowInstallModal(true);
          }}
        >
          밴드등록
        </SyncButton>
      );
  };

  const renderEnteredTime = () => {
    const enterAtAsMinutes = dayjs().diff(band?.result.userTicket?.enterAt, 'minute');
    const hour = Math.floor(enterAtAsMinutes / 60);
    const minutes = enterAtAsMinutes % 60;
    if (band)
      return (
        <Time className={showTimeLeft ? 'show' : ''}>
          입장 후{' '}
          <strong>
            {hour}시간 {minutes}분
          </strong>{' '}
          지났습니다
        </Time>
      );
    else return null;
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
    return () => window.removeEventListener('scroll', updateScroll);
  });

  useEffect(() => {
    if (router.pathname.startsWith('/ticket')) setShowBadge(true);
  }, [router.pathname]);

  return (
    <>
      <TopWrapper>
        <Container className={scrollPosition < 24 ? '' : 'shadowed'}>
          <Title onClick={() => router.push('/')}>
            <Image src={Logo} alt="logo" width="12px" height="12px" />
            <TextTitle size="16px" margin="2px 0 0 8px" weight="bold" color="#000">
              {title ? title : '대전신세계점'}
            </TextTitle>
          </Title>
          <Menu>
            {showBadge && isLoggedIn && (
              <MyTicket>
                {!!numOfUsableTicket && <div className="count">{numOfUsableTicket}</div>}
                <Image
                  src={myticket}
                  alt="icon"
                  width={32}
                  height={32}
                  className="next_image"
                  onClick={() => router.push('/ticketbox')}
                />
              </MyTicket>
            )}
            {renderBandStatus()}
            {router.pathname === '/users' && (
              <Image
                src={Setting}
                alt="icon"
                width="22px"
                height="22px"
                onClick={() => router.push('/users/setting')}
              />
            )}
          </Menu>
        </Container>
        {renderEnteredTime()}
      </TopWrapper>
      <InstallModal isModalOpen={showInstallModal} setModalOpen={setShowInstallModal} />
    </>
  );
};

const TopWrapper = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
`;

const Container = styled.section`
  top: 0;
  padding: 19px 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  z-index: 20;
  &.shadowed:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    height: 8px;
    background-image: url(/bg-nav-shadow.png);
    background-repeat: repeat-x;
    background-size: 16px auto;
    opacity: 0.3;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  img {
    cursor: pointer;
  }
`;

const SyncButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  height: 30px;
  padding: 0 10px 0 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  &.synced {
    color: #3491ff;
    border: 1px solid #3491ff66;
    img {
      width: 14px;
    }
  }
`;

const MyTicket = styled.div`
  position: relative;
  cursor: pointer;

  .count {
    position: absolute;
    right: -6px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: red;
    font-family: Roboto Condenced;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.5em;
    color: #fff;
    text-align: center;
    z-index: 2;
  }
`;
