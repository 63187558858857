import { useRouter } from 'next/router';
import Image from 'next/image';
import { useEffect, useState, memo } from 'react';
import styled from 'styled-components';
import home from '../../../public/home-icon.svg';
import homeActive from '../../../public/home-icon-active.svg';
import ticket from '../../../public/ticket-icon.svg';
import ticketActive from '../../../public/ticket-icon-active.svg';
import play from '../../../public/play-button.svg';
import playActive from '../../../public/play-button-active.svg';
import menu from '../../../public/menu.svg';
import menuActive from '../../../public/menu-active.svg';

const BottomNav = () => {
  const router = useRouter();
  const [navHref, setNavHref] = useState<string>('');

  useEffect(() => {
    if (location.pathname === '/') {
      setNavHref('home');
    }
    if (location.href.includes('ticket')) {
      setNavHref('ticket');
    }
    if (location.href.includes('play')) {
      setNavHref('play');
    }
    if (location.href.includes('users')) {
      setNavHref('users');
    }
  }, []);

  return (
    <>
      <div className="app-footer"></div>
      <NavContainer>
        <div onClick={() => router.push('/')}>
          <Image src={navHref === 'home' ? homeActive : home} alt="home-icon" width="22" height="22" />
          <span className={navHref === 'home' ? 'active' : ''}>홈</span>
        </div>

        <div onClick={() => router.push('/ticket')}>
          <Image src={navHref === 'ticket' ? ticketActive : ticket} alt="home-icon" width="22" height="22" />
          <span className={navHref === 'ticket' ? 'active' : ''}>티켓</span>
        </div>

        <div onClick={() => router.push('/play')}>
          <Image src={navHref === 'play' ? playActive : play} alt="home-icon" width="22" height="22" />
          <span className={navHref === 'play' ? 'active' : ''}>플레이</span>
        </div>

        <div onClick={() => router.push('/users')}>
          <Image src={navHref === 'users' ? menuActive : menu} alt="home-icon" width="22" height="22" />
          <span className={navHref === 'users' ? 'active' : ''}>메뉴</span>
        </div>
      </NavContainer>
    </>
  );
};

export default memo(BottomNav);

const NavContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  position: fixed;
  bottom: 0;
  height: 56px;
  text-align: center;
  border-top: 1px solid #e9ebee;
  background-color: #ffffff;
  width: 100%;
  max-width: 640px;
  z-index: 20;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      font-size: 12px;
      color: #374553;
      &.active {
        color: #00a7e1;
      }
    }

    img {
      width: 18px;
      margin-bottom: 4px;
    }
  }
`;
